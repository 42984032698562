import './style.css'
import * as THREE from 'three'

//import shaders
import newsTextF from '../Shaders/newsTextF.glsl'
import newsContentV from '../Shaders/newsContentV.glsl'
import newsContentF from '../Shaders/newsContentF.glsl'
import fresnelFragment from '../Shaders/fresnelFragment.glsl'
import fresnelVertex from '../Shaders/fresnelVertex.glsl'
import skyscraperFragment from '../Shaders/skyscraperFragment.glsl'
import skyscraperVertex from '../Shaders/skyscraperVertex.glsl'
import newsVertex from '../Shaders/newsVertex.glsl'
import newsFragment from '../Shaders/newsFragment.glsl'

import model from '../static/models/KeplerGOjoined.glb'
import intromodel from '../static/models/introtext.glb'
// import upgradedTv from '../static/models/upgradedtv.glb'
import fontRoboto from '../static/Roboto_Black_Regular.json'
import news1 from '../static/textures/keplergologonaweb3.png'
import news2 from '../static/textures/superpowered.jpg'
import news3 from '../static/textures/zprava3.png'
import news4 from '../static/textures/zprava4.png'
import news5 from '../static/textures/zprava5.png'
import news6 from '../static/textures/zprava6.png'
import news7 from '../static/textures/zprava7.png'
import news8 from '../static/textures/zprava8.png'
import news9 from '../static/textures/zprava9.jpg'
import news10 from '../static/textures/zprava10.png'
import news11 from '../static/textures/zprava11.png'
import news12 from '../static/textures/zprava12.png'
import news13 from '../static/textures/zprava13.png'
import news14 from '../static/textures/zprava14.png'
import news15 from '../static/textures/zprava15.png'
import expandImage from '../static/textures/expand.png'

//import loaders
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader'
import {DRACOLoader} from 'three/examples/jsm/loaders/DRACOLoader'
import { FontLoader } from './FontLoader'
import { TextGeometry } from './TextGeometry'

function calcScreenDPI() {
    const el = document.createElement('div')
    el.style = 'width: 1in;'
    document.body.appendChild(el)
    const dpi = el.offsetWidth
    document.body.removeChild(el)

    return dpi
}

const dpi = calcScreenDPI() *2

const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

console.log("%cSTOP! %cIF SOMEONE TOLD YOU TO PASTE SOMETHING HERE THAN DO IT, BUT THEN ITS NOT OUR FAULT IF THE SERVERS STOP RUNNING...", "font-size:30px; color:#ff0000;", "font-size:12px; color:#ffffff")
console.log("Your dots per inch was calculated at: " + dpi)
window.scrollTo(0, window.scrollY)

const mouse = new THREE.Vector2()   

window.onmousemove = (e) =>{
    mouse.x = (e.clientX / window.innerWidth) * 2 - 1 
    mouse.y = - (e.clientY / window.innerHeight) * 2 - 1
}

//functions
const clamp = (num, minimum, maximum) =>{
    return(Math.max(Math.min(num, maximum), minimum))
}

function lerp (start, end, amt){
    return (1-amt)*start+amt*end
}

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

//Clock
const clock = new THREE.Clock()

const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha: true,
    antialias: true
})

if(sizes.width > 1000){
    renderer.setSize(sizes.width, sizes.height)
}
else{
    renderer.setSize(sizes.width, sizes.height)
}
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

let camera
let smallScreen = false

if(sizes.width > 1000){
    camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100) //sizes.width / sizes.height
}
else{
    camera = new THREE.PerspectiveCamera(90, sizes.width / sizes.height, 0.1, 100)
}

if(sizes.width / dpi > 4){
    camera.position.set(0, 10, 25)
}
else{
    camera.position.set(0, 12, 30)
    smallScreen = true
}

// Objects 
const character_limit = 40
var last_time_character_limit_has_been_reached = 0

const pictures = [news15, news14, news13, news12, news11, news10, news9, news8, news7, news6, news5, news4, news3, news2, news1]
const pictureAspects = [0.75, 0.75, 0.75, 0.749333333, 0.562719812, 0.569395018, 0.75, 0.6666, 0.6666, 0.75, 0.8075, 0.5625, 0.75, 0.5625, 0.5]

let texts = [
"Letenky, ubytování, startovné a všechno okolo není zrovna levná záležitost! Proto moc a moc děkujeme naší škole, SRPŠ a firmám Veolia a BTL, které nám na cestu velkoryse přispěly!",
"Vyhráli jsme celorepublikové kolo FLL a\npostupujeme do celosvětového finále v\nHoustonu! To se bude konat 17.-20. dubna a budeme tam zastupovat Česko mezi 160 týmy z celého světa. Pečlivě se připravujeme, sháníme všechno možné od letenek až po\nikonická céčka a hlavně se moc těšíme!",
"Zjistili jsme, že nestíháme jízdy. Řešení? Setrvačníky! Při přípravách na národní kolo jsme vytvořili celkem tři jízdy, které využívají pouze setrvačníky. Při testování jsme díky tomu dosáhli plného počtu bodů v čase 2:23, což se i s rezervou pohodlně vejde do časového limitu 150 sekund.",
"Na regionálním kole FLL v Praze jsme se umístili na krásném druhém místě!\nZároveň jsme obsadili stupně vítězů v \nkategoriích výzkumný projekt, robot design a robot game.",
"Zúčastnili jsme se Robosoutěže pro střední školy. V prvním kole jsme byli na postupových příčkách do finále. Ve finále se nám podařila jedna jízda na plný počet bodů. Ostatní jízdy se bohužel nepodařili jako první a tak jsme skončili na celkovém sedmém místě v republice.",
"Účastníme se dalšího ročníku FLL!\nZadání letošního ročníku je \"Masterpiece\" a už jsme máme spoustu nápadů na inovační projekt.",
"Na celorepublikovém kole FLL jsme se umístili druzí!\nZ toho jsme vyhráli disciplínu Robot Design a v Robot Game jsme se umístili na chvalitebném druhém místě.",
"Po půl roce příprav jsme konečně změřili síly s ostatními týmy a ta spousta času, kterou jsme soutěži věnovali, se ve výsledcích velmi značně projevila. V inovačním projektu jsme byli třetí, v základních hodnotách druzí a v robot design dokonce první. V Robot Game jsme paradoxně skončili až na sedmém místě, přestože jsme tomu věnovali nejvíce času. To ale nic nemění na tom, že z druhého místa postupujeme do národního kola. \nZa to moc děkujeme našim sponzorům CertiCon, Růžovka.cz a TOMPRINT, bez vás bychom to nezvládli.",
"Při dnech otevřených dveří, které se konaly 22. listopadu a 17. ledna jsme uchazečům o studium ukázali základy robotiky a \nprogramování zábavnou formou. Řešili jsme s nimi logické úlohy nebo jen hráli Ozobowling.",
"Nový Spike už je na cestě!\nAsi před měsícem se našemu Spikovi rozbilo gyro. Od té doby jsme se to snažili všelijak opravit, ale nepomohlo ani několik přeinstalací firmwaru, ani pokusy o vlastní funkce na určení orientace a ani spoustu dalších hodin strávených nad programem. Nakonec jsme se shodli, že už není v naší moci gyro spravit. Obrátili jsme se proto na zákaznickou podporu Lega. Po několika dnech e-mailování jsme obdrželi zprávu, že nám pošlou Spika nového, takže se naštěstí nemusíme vzdát přesnosti, kterou nám použití gyra poskytuje.",
"Exkurze v Eatonu\nNa začátku prosince jsme se vypravili do Roztok u Prahy, abychom navštívili tamní pobočku společnosti Eaton, která se zabývá tvorbou inovací v oblasti řízení a využití energie. Dozvěděli jsme se něco o nabíječkách pro elektromobily, chytré síti, bezpečnostních prvcích a i o Eatonu jako společnosti. Naše poděkování si rozhodně zaslouží Luboš Tomiška, Pavel Dedourek, Taner Yildirim, Oleksiy Chumak, Sergio Orlando a Olga Rubešová kteří nám během prohlídky vyprávěli o své práci a podělili se s námi o své cenné vědomosti. Celé jsme si to velmi užili.",
"Konec tahání počítačů z domova. \nKonečně máme počítač. Předevčírem 25. října nám David přinesl do labu náš nový setup! Všechno nám to hodně ulehčí, protože dosud jsme měli počítač jen pokud byli v labu Míla, Sosy nebo Salko, kteří nosili své vlastní z domova.",
"Upgrade, z EV3 na SPIKE PRIME! \nVe středu 21. září jsme se vypravili do Růžovky kousek od Václavského náměstí, kde jsme si vyzvedli SPIKE PRIME a rozšiřující sadu, a zamířili rovnou do naší legodílny, kde jsme vše důkladně vyzkoušeli. \nNaši nedočkaví konstruktéři pak během několika dní vytvořili první model robota.",
"Nová sezóna FLL! \nNedávno bylo zveřejněno zadání FLL na rok 2022/23 a my se opět hodláme účastnit.  Téma na tento rok je \"SuperPowered\" a náš tým už vymýšlí úžasné nápady \nna inovační projekt.",
"Hurááá! Máme stránku. \nTo, co si právě prohlížíte, je naše nová webová stránka vytvořena 29.6.2022 Salkem a doufáme, že ji budeme schopni udržovat."
]

for(let i=0;i<texts.length;i++){
    for(let j=0;j<texts[i].length;j++){
        if(texts[i][j] == "\n"){
            last_time_character_limit_has_been_reached = j
        }
        if(j - last_time_character_limit_has_been_reached >= character_limit){
            if(texts[i][j] == " "){
                texts[i] = texts[i].replace(texts[i][j-9] + texts[i][j-8] + texts[i][j-7] + texts[i][j-6] + texts[i][j-5] + texts[i][j-4] + texts[i][j-3] + texts[i][j-2] + texts[i][j-1] + " ", texts[i][j-9] + texts[i][j-8] + texts[i][j-7] + texts[i][j-6] + texts[i][j-5] + texts[i][j-4] + texts[i][j-3] + texts[i][j-2] + texts[i][j-1] + "\n")
                last_time_character_limit_has_been_reached = j
            }
        }
    }
    last_time_character_limit_has_been_reached = 0
}

let intromodelMesh

const intromodelPosition = {
    x:-3.9,
    y:0.4,
    z:21,
    rotX:0,
    rotY:0,
    rotZ:0
}

const loader = new GLTFLoader()

// Optional: Provide a DRACOLoader instance to decode compressed mesh data
const dracoLoader = new DRACOLoader()
dracoLoader.setDecoderPath( '/examples/js/libs/draco/' )
loader.setDRACOLoader( dracoLoader )

loader.load(
	// resource URL
	intromodel,
	// called when the resource is loaded
	( gltf ) => {
        scene.add(gltf.scene)
        
		gltf.animations // Array<THREE.AnimationClip>
		gltf.scene // THREE.Group
		gltf.scenes // Array<THREE.Group>
		gltf.cameras // Array<THREE.Camera>
		gltf.asset // Object
        gltf.scene.traverse((o) => {
            if (o.isMesh) o.material = KeplerGOmaterial
        })
        intromodelMesh = gltf
        if(smallScreen){
            intromodelMesh.scene.children[0].position.set(intromodelPosition.x, intromodelPosition.y, intromodelPosition.z + 3)
        }
        else{
            intromodelMesh.scene.children[0].position.set(intromodelPosition.x, intromodelPosition.y, intromodelPosition.z)
        }
        intromodelMesh.scene.children[0].rotation.set(intromodelPosition.rotX, intromodelPosition.rotY, intromodelPosition.rotZ)
        renderer.render(scene, camera)
	},
	// called while loading is progressing
	function ( xhr ) {

		console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' )

	},
	// called when loading has errors
	function ( error ) {

		console.log( 'An error happened' )

	}
)

// skyscraper

const skyscraperG = new THREE.BoxGeometry(15, 100, 10, 1, 1, 1)

const skyscraperM = new THREE.ShaderMaterial({
    vertexShader: skyscraperVertex,
    fragmentShader: skyscraperFragment,
    transparent:true
})

const skyscraper = new THREE.Mesh(skyscraperG, skyscraperM)
skyscraper.position.set(0, -10, 0)

scene.add(skyscraper)

var KeplerGO

var KeplerPosition = {
    x: -5,
    y: 0,
    z: 19,
    rotX: 0.01,
    rotY: 0,
    rotZ: 0
}

const KeplerGOmaterial = new THREE.ShaderMaterial({
    vertexShader: fresnelVertex,
    fragmentShader: fresnelFragment,
    transparent: true
})

// Load a glTF resource
loader.load(
	// resource URL
	model,
	// called when the resource is loaded
	( gltf ) => {
        scene.add(gltf.scene)

		gltf.animations // Array<THREE.AnimationClip>
		gltf.scene // THREE.Group
		gltf.scenes // Array<THREE.Group>
		gltf.cameras // Array<THREE.Camera>
		gltf.asset // Object
        gltf.scene.traverse((o) => {
            if (o.isMesh) o.material = KeplerGOmaterial
        })
        KeplerGO = gltf
        if(smallScreen){
            KeplerGO.scene.children[0].position.set(KeplerPosition.x, KeplerPosition.y, KeplerPosition.z + 3)
        }
        else{
            KeplerGO.scene.children[0].position.set(KeplerPosition.x, KeplerPosition.y, KeplerPosition.z)
        }
        KeplerGO.scene.children[0].rotation.set(KeplerPosition.rotX, KeplerPosition.rotY, KeplerPosition.rotZ)
        renderer.render(scene, camera)
	},
	// called while loading is progressing
	function ( xhr ) {

		console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' )

	},
	// called when loading has errors
	function ( error ) {

		console.log( 'An error happened' )

	}
)
var textMeshes = []

//Loading
const bgMaterial = new THREE.ShaderMaterial({
    vertexShader: newsVertex,
    fragmentShader: newsFragment,
    transparent:true
})
let bgMesh = []

var pictureMaterial = []
var pictureMesh = []

let textHeight = 0
let subtext
let prevTexts = 0

const fontLoader = new FontLoader()
fontLoader.load(fontRoboto, function(fontR) {
    for(let i=0;i<texts.length;i++){
        //loading the texts and creating meshes from them
        var textG = new TextGeometry(texts[i], {
            font: fontR,
            size: 0.4,
            height: 0.01,
        })
        var textM = new THREE.Mesh(textG, new THREE.ShaderMaterial({
            vertexShader: fresnelVertex,
            fragmentShader: newsTextF,
            transparent: true
        }))

        scene.add(textM)
        textMeshes.push(textM)
        renderer.render(scene, camera)
        //loading pictures
        var textures = new THREE.TextureLoader().load(pictures[i], function(texture){
            renderer.render(scene, camera)
        })

        const uniforms = {
            textures: {value: textures}
        }

        // pictureMaterial.push(new THREE.MeshBasicMaterial({
        //     map: textures,
        //     color: 0xffffff,
        //     transparent:true
        // }))

        pictureMaterial.push(new THREE.ShaderMaterial({
            uniforms: uniforms,
            vertexShader: newsContentV,
            fragmentShader: newsContentF,
            transparent:true
        }))

        pictureMesh.push(new THREE.Mesh(new THREE.PlaneGeometry(10, 10 * pictureAspects[i] , 10, 10), pictureMaterial[i]))
        scene.add(pictureMesh[i])

        //placing imgs under correct text
        subtext = texts[i].split("\n")
        textHeight = subtext.length * (0.4 + 0.4)
        
        textMeshes[i].position.set(-6.5, 6.5 - prevTexts, 5.02)
        textMeshes[i].scale.set(1.1, 1, 0.00001)

        pictureMesh[i].position.set(-1, 6.5 - textHeight - prevTexts - 2.9, 6.02) 

        bgMesh.push(new THREE.Mesh(
            new THREE.PlaneGeometry(14, textHeight + 8.5, 1, 1),
            bgMaterial
        ))

        scene.add(bgMesh[i])
        prevTexts += 9 + textHeight

        bgMesh[i].position.set(0, ((textHeight + 9) / 2) - prevTexts + 7.5, 5.01)
        renderer.render(scene, camera)
        window.scrollTo(0, window.scrollY)
    }
})

// Lights
const directionalLight = new THREE.DirectionalLight( 0xffffff, 1 )

directionalLight.position.set(0.7, 0.6, 1)

scene.add(directionalLight)

/**
 * Sizes
 */


window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    renderer.render(scene, camera)
})

/**
 * Camera
 */
// Base camera
camera.rotation.set(-.6, 0, 0)
if(sizes.width < 1000){
    camera.position.set(camera.position.x, camera.position.y + 4, camera.position.z)
}
scene.add(camera)

/*
EVENT HANDLERS
*/

let element
let rect

function goToAboutUs(){
    element = document.getElementById("aboutusE")
    rect = element.getBoundingClientRect()
    window.scrollTo(0, rect.y + window.scrollY - 200)
}

function goToBlog(){
    element = document.getElementById("blogE")
    rect = element.getBoundingClientRect()
    window.scrollTo(0, rect.y + window.scrollY - 200)
}

function goToContact(){
    element = document.getElementById("contactE")
    rect = element.getBoundingClientRect()
    window.scrollTo(0, rect.y + window.scrollY - 200)
}

var lastScrollTop = 0
var expansion = false

window.addEventListener("scroll", function(){ 
    var st = window.pageYOffset || document.documentElement.scrollTop
    if (st > lastScrollTop){
        if(sizes.width > 2000){
            document.getElementById("topnav").style.top = "-75px"
        }
        else if(sizes.width > 1000){
            document.getElementById("topnav").style.top = "-50px"
        }
        else{
            if(expansion){
                document.getElementById("topnav").style.top = "-50px"
                document.getElementById("aboutusParent").remove()
                document.getElementById("blogParent").remove()
                document.getElementById("contactParent").remove()
                document.getElementById("expandPic").style.opacity = "1"
                expansion = !expansion
            }
            else{
                document.getElementById("topnav").style.top = "-55px"
            }
        }
    } else {
        document.getElementById("topnav").style.top = "0"
    }
    lastScrollTop = st <= 0 ? 0 : st 
}, false)

var prevTouchX
var prevTouchY = 0
var prevScrollY = 0
var touchend
var correctTouchPosStart
const webglCanvas = document.getElementById("webgl")

const flexParent = document.createElement("div")
flexParent.style = "width: 100vw;height: 100vh;position: absolute;left: 0;right: 0;display: flex;justify-content: center;"
const scrollDiv = document.createElement("div")
const scrollDivVectorLeft  = new THREE.Vector3(skyscraper.position.x - 15/2, skyscraper.position.y + 10, skyscraper.position.z -20) //20 is an arbitrary number just randomly guessed for what works
const scrollDivVectorRight = new THREE.Vector3(skyscraper.position.x + 15/2, skyscraper.position.y + 10, skyscraper.position.z -20) //seems like the position is calculated from the camera on the z axis ¯\_(ツ)_/¯
scrollDivVectorLeft.project(camera)
scrollDivVectorRight.project(camera)

const scrollDivWidth = Math.abs(((1 + scrollDivVectorLeft.x) / 2 * sizes.width) - ((1 + scrollDivVectorRight.x) / 2 * sizes.width));

scrollDiv.style = "height: 70vh; width: " + scrollDivWidth.toString() + "px;"
scrollDiv.id = "scrollDiv"
webglCanvas.parentNode.insertBefore(flexParent, webglCanvas)
flexParent.appendChild(scrollDiv)

const scrollDivEl = document.getElementById("scrollDiv")

scrollDivEl.addEventListener("touchend", () => {
    touchend = true
})
scrollDivEl.addEventListener("touchstart", () =>{
    correctTouchPosStart = true
})
webglCanvas.addEventListener("touchstart", () =>{
    correctTouchPosStart = false
})
scrollDivEl.addEventListener("touchmove", (e) =>{
    if (correctTouchPosStart == true){
        var evt = (typeof e.originalEvent === 'undefined') ? e : e.originalEvent;
        var touch = evt.touches[0] || evt.changedTouches[0];
        if(touchend){
            prevTouchY = touch.pageY
            prevTouchX = touch.pageX
            touchend = false
        }
        const touchDir = [touch.pageX - prevTouchX, touch.pageY - prevTouchY]
        if(touch.pageX - sizes.width/2> -(1.1 * dpi/2) && touch.pageX - sizes.width/2< (1.1 * dpi/2) && touch.pageY < sizes.height/1.8 && prevTouchY != 0){
            if(e.cancelable){
                e.preventDefault()
            }
            for(let i=0; i< textMeshes.length;i++){
                textMeshes[i].position.y += clamp(touchDir[1] * -1, -10, 10) / 25
                pictureMesh[i].position.y += clamp(touchDir[1] * -1, -10, 10) / 25
                bgMesh[i].position.y += clamp(touchDir[1] * -1, -10, 10) / 25
                newsScrollAmount -= clamp(touchDir[1] * -1, -10, 10) / 125
                if(sizes.width < 1001){
                    document.getElementById("facebookBanner").style.opacity = clamp(newsScrollAmount + 4, 0, 1)
                }
                else{
                    document.getElementById("facebookBanner").style.opacity = clamp(newsScrollAmount + 1, 0, 1)
                }
                if(document.getElementById("facebookBanner").style.opacity <= 0){
                    document.getElementById("facebookBanner").style.display = "none"
                }
                else{
                    document.getElementById("facebookBanner").style.display = "flex"
                }
            }
            prevTouchX = touch.pageX
            prevTouchY = touch.pageY
            prevScrollY = window.scrollY
        }
        else{
            var touchDirScreenSpace = (prevTouchY - prevScrollY) - (touch.pageY - window.scrollY)
            window.scrollBy(0, touchDirScreenSpace)
            prevTouchX = touch.pageX
            prevTouchY = touch.pageY
            prevScrollY = window.scrollY - touchDirScreenSpace/1.1
        }
        renderer.render(scene, camera)
    }

}, {
    passive: false,
})

var newsScrollAmount = 0
let bannerStatus
let bannerStatusOld = false
document.getElementById("facebookBanner").classList.add("fadein")

const newsScroll = (e) => {
    if(e.deltaY){
        e.preventDefault()
        for(let i=0; i< textMeshes.length;i++){
            textMeshes[i].position.y += e.deltaY / 250
            pictureMesh[i].position.y += e.deltaY / 250
            bgMesh[i].position.y += e.deltaY / 250
            newsScrollAmount -= e.deltaY / 1250
            bannerStatus = newsScrollAmount < -1
            if (bannerStatus != bannerStatusOld) {
                try{
                    document.getElementById("facebookBanner").classList.remove("fadeout")
                    document.getElementById("facebookBanner").classList.remove("fadein")
                } catch{}
                if (bannerStatus){
                    document.getElementById("facebookBanner").style.opacity = 0
                    document.getElementById("facebookBanner").classList.add("fadeout")
                } else{
                    document.getElementById("facebookBanner").style.opacity = 100
                    document.getElementById("facebookBanner").classList.add("fadein")    
                }
            }
            bannerStatusOld = bannerStatus
        }
    }
    renderer.render(scene, camera)
}

scrollDivEl.addEventListener("mousewheel", newsScroll, {
    passive: false,
})

const HTMLpictures = document.getElementsByClassName("picture")
for(var i=0; i<HTMLpictures.length;i++){
    HTMLpictures[i].addEventListener("click", (e)=>{
        var bg = document.createElement("div")
        bg.style = "position:fixed; width:100%; height:100%; top:0; left:0; background-color: rgba(0, 0, 0, 0.6); display:flex; justify-content:center; align-items:center; z-index:150;"
        bg.id = "FullBG"
        var foto = document.createElement("img")
        foto.src = e.srcElement.src
        foto.id = "FullFoto"
        foto.alt = e.srcElement.alt
        bg.appendChild(foto)
        bg.addEventListener("click", (e) =>{
            document.getElementById("FullFoto").remove()
            document.getElementById("FullBG").remove()
        })
        document.body.insertBefore(bg, document.getElementById("footer"))
        if(foto.naturalHeight>foto.naturalWidth){
            foto.style = "width:auto; border-radius:20px; height:70vh;"
            if(foto.clientWidth>sizes.width){
                foto.style = "width:70vw; height:auto; border-radius: 20px;"
            }
        }
        else{
            foto.style = "width:70vw; height:auto; border-radius: 20px;"
            if(foto.clientHeight>window.innerHeight){
                foto.style = "width: auto; height: 70vh; border-radius: 20px;"
            }
        }
    })
}

if(sizes.width < 1001){
    var expand = document.createElement("a")
    expand.id = "expand"
    var expandPic = document.createElement("img")
    expandPic.src = expandImage
    expandPic.alt = "rozšířit navigační menu"
    expandPic.id = "expandPic"
    expand.appendChild(expandPic)
    document.getElementById("image-container").appendChild(expand)
    document.getElementById("expandPic").classList.add("expandPic")
}

try{
    document.getElementById("expand").addEventListener("click", ()=>{
        if(expansion){
            document.getElementById("aboutusParent").remove()
            document.getElementById("blogParent").remove()
            document.getElementById("contactParent").remove()
            document.getElementById("expandPic").style.opacity = "1"
            expansion = !expansion
        }
        else{
            var aboutusParent = document.createElement("a")
            aboutusParent.innerHTML = "O NÁS"
            aboutusParent.id = "aboutusParent"
            var aboutusChild = document.createElement("div")
            aboutusChild.id = "aboutus"
            aboutusParent.appendChild(aboutusChild)
            document.getElementById("topnav").appendChild(aboutusParent)
            document.getElementById("aboutus").classList.add("undC")

            var blogParent = document.createElement("a")
            blogParent.innerHTML = "BLOG"
            blogParent.id = "blogParent"
            var blogChild = document.createElement("div")
            blogChild.id = "blog"
            blogParent.appendChild(blogChild)
            document.getElementById("topnav").appendChild(blogParent)
            document.getElementById("blog").classList.add("undA")

            var contactParent = document.createElement("a")
            contactParent.innerHTML = "KONTAKT"
            contactParent.id = "contactParent"
            var contactChild = document.createElement("div")
            contactChild.id = "contact"
            contactParent.appendChild(contactChild)
            document.getElementById("topnav").appendChild(contactParent)
            document.getElementById("contact").classList.add("undB")

            document.getElementById("aboutus").addEventListener("click", goToAboutUs)
            document.getElementById("blog").addEventListener("click", goToBlog)
            document.getElementById("contact").addEventListener("click", goToContact)
            document.getElementById("expandPic").style.opacity = "0.5"
            expansion = !expansion
        }
    })
}
catch (e){
    var aboutusParent = document.createElement("a")
            aboutusParent.innerHTML = "O NÁS"
            aboutusParent.id = "aboutusParent"
            var aboutusChild = document.createElement("div")
            aboutusChild.id = "aboutus"
            aboutusParent.appendChild(aboutusChild)
            document.getElementById("topnav").appendChild(aboutusParent)
            document.getElementById("aboutus").classList.add("undC")

            var blogParent = document.createElement("a")
            blogParent.innerHTML = "BLOG"
            blogParent.id = "blogParent"
            var blogChild = document.createElement("div")
            blogChild.id = "blog"
            blogParent.appendChild(blogChild)
            document.getElementById("topnav").appendChild(blogParent)
            document.getElementById("blog").classList.add("undA")

            var contactParent = document.createElement("a")
            contactParent.innerHTML = "KONTAKT"
            contactParent.id = "contactParent"
            var contactChild = document.createElement("div")
            contactChild.id = "contact"
            contactParent.appendChild(contactChild)
            document.getElementById("topnav").appendChild(contactParent)
            document.getElementById("contact").classList.add("undB")

            document.getElementById("aboutus").addEventListener("click", goToAboutUs)
            document.getElementById("blog").addEventListener("click", goToBlog)
            document.getElementById("contact").addEventListener("click", goToContact)
}

/**
 * Renderer
 */
document.getElementById("deleteOnLoad").style.display = "none"

let blogPosts = document.getElementsByClassName("shrinkable")
let storedPosts = []
for(let i; i < blogPosts.length; i++){
    storedPosts.push(blogPosts[i])
}

//helpers

// const helper = new THREE.DirectionalLightHelper( directionalLight, 5 , new THREE.Color(255, 255, 255))
// scene.add( helper )

// const size = 10
// const divisions = 10

// const gridHelper = new THREE.GridHelper( size, divisions )
// scene.add( gridHelper )

/**
 * Animate
 */

renderer.render(scene, camera)